/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "bukkenScanDB-main",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "bukken-scan-uploaded-maisoku-bucket94129-main",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
